<template>
    <Loader v-if="loading"/>
    <div v-else>
        <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5 mt-5">
            <div class="w-25">
                <router-link v-if="prevRoute" :to="prevRoute.fullPath" replace>
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ !mobile() ? 'Retour' : '' }}
                    </span>
                </router-link>
            </div>
            <div class="d-flex flex-column align-items-center">
                <h1 class="ml-5 mb-0 align-self-center w-100">{{ classroom.classe.title }}</h1>
            </div>
            <div v-if="!mobile()" class="w-25"></div>
        </div>
        <div class="card card-custom gutter-b align-items-center justify-content-center p-5 mt-5">
            <BSpinner label="Spinning" variant="primary" class="mb-3"></BSpinner>
            <p>Veuillez patienter</p>
            <p>Votre tuteur n'a pas encore démarré la vidéoconférence, merci de patienter quelques instants.</p>
        </div>
    </div>
</template>

<script>
import {GET_VISIO} from "../../core/services/store/api/videoconference.service";
import Loader from "../content/Loader.vue";
import {GET_CLASSROOM} from "../../core/services/store/api/classroom.service";

export default {
    name: "WaitingRoom",
    components: {Loader},
    data() {
        return {
            loading: true,
            prevRoute: null,
            visio: null,
            classroom: null,
            tuteur: false,
        }
    },
    async mounted() {
        if (!this.$route.params.visioId) {
            this.$router.push({name: 'dashboard'});
        } else {
            await this.checkTuteur([1]);
            this.getVisio();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    methods: {
        mobile() {
            return window.innerWidth < 768;
        },
        async checkTuteur(rightIds) {
            if ((this.$store.getters.currentUser && this.$store.getters.currentUser.rights && this.$store.getters.currentUser.rights.academy)) {
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        },
        getVisio() {
            //si this.visio.is_active === 0, on refait la requête toutes les 15 secondes
            //si this.visio.is_active === 1, on redirige vers la visio

            this.$store.dispatch(GET_VISIO, this.$route.params.visioId)
                .then(response => {
                    //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                    if (this.tuteur) {
                        if (response.data.tuteur_id !== this.$store.getters.currentUser.id) {
                            this.$router.push({name: 'dashboard'});
                        }
                    }
                    this.visio = response.data;
                    if (!this.classroom) {
                        this.getClassroom();
                    }
                    if (this.visio.is_active === 0) {
                        setTimeout(() => {
                            this.getVisio();
                        }, 15000);
                    } else {
                        this.$router.push({name: 'classroom-signature', params: {visioId: this.visio.id}});
                    }
                })
                .catch(() => {
                    this.$router.push({name: 'dashboard'});
                });
        },
        async getClassroom() {
            this.$store.dispatch(GET_CLASSROOM, this.visio.session_id)
                .then(response => {
                    //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                    if (this.tuteur) {
                        if (response.data.tuteur_id !== this.$store.getters.currentUser.id) {
                            this.$router.push({name: 'dashboard'});
                        }
                    }
                    this.classroom = response.data;
                    this.loading = false;
                });
        }
    }
}
</script>